<template>
  <div
    class="absolute w-full h-full z-50 text-white flex justify-center p-8 cursor-pointer"
    style="background:rgba(0,0,0,0.75)"
    @click.prevent="close"
  >
    <div class="overlay-content flex flex-col justify-between items-center w-full">
      <div class="flex justify-center text-sm m-4">
        <a
          href="#"
          class="uppercase font-bold border-b-4 border-blue-600 mx-4"
        >Quick Guide</a>
        <a
          href="#"
          class="uppercase font-bold mx-4"
        >Full Controls</a>
      </div>

      <div class="flex w-full">
        <div class="flex-1 p-6 text-center">
          <fa-icon
            icon="sync-alt"
            size="lg"
          />
          <h3 class="block font-base font-bold my-4">
            Orbit Around
          </h3>
          <p>
            Left click and drag, <span class="italic">or</span><br>
            One finger drag (touch)
          </p>
        </div>

        <div class="flex-1 p-6 text-center">
          <fa-icon
            icon="magnifying-glass-plus"
            size="lg"
          />
          <h3 class="block font-base font-bold my-4">
            Zoom
          </h3>
          <p>
            Mouse scroll anywhere, <span class="italic">or</span><br>
            Pinch (touch)
          </p>
        </div>

        <div class="flex-1 p-6 text-center">
          <fa-icon
            icon="arrows"
            size="lg"
          />
          <h3 class="block font-base font-bold my-4">
            Pan
          </h3>
          <p>
            Right click + drag, <span class="italic">or</span><br>
            Two fingers drag (touch)
          </p>
        </div>
      </div>

      <div class="reset m-4">
        <button
          type="button"
          name="button"
          class="btn btn-xs uppercase"
        >
          Reset Camera View
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewerHelpOverlay',

  methods: {
    close () {
      this.$emit('close-overlay')
    }
  }
}</script>
